code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.RocheTopBarInner-rightIcons-3 {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  margin-right: 0.625rem;
}

@media only screen and (max-width: 960px) {
  .RocheTopBarInner-rightIcons-3 {
    justify-content: center;
    margin-right: 0.625rem;
  }
}
*::-webkit-scrollbar {
  width: 0.75rem;
  background: #fafafa;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #bababa;
  border-radius: 0.375rem;
  border: 3px solid #fafafa;
}
