@import "~@one/web-components/dist/owc/owc.css";
@import "~@one/icons/dist/one-icons-legacy.css";
@import "~@one/icons/dist/one-icons-filled.css";
@import "~@one/icons/dist/one-icons-outlined.css";
@import "~@one/roche-font/roche-font.css";

:root {
  font-size: 16px;
}

body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  font-family: var(--one-typography-font-family);
  background-color: var(--one-color-background-base-1) !important;
  color: var(--one-color-foreground-base-1);

  #root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
